<template>
  <div class="modal fade" ref="MdlDescargaConfiguracion" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header bg-success">
          <h5 class="modal-title text-white">
            Descarga del archivo de configuración para <strong>{{identidad.toUpperCase()}}</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Da click sobre el botón del sistema operativo para el que vas a 
            descargar el archivo de configuración:
          </p>
          <div>
            <table class="table table-hover">
              <tbody>
                <tr v-for="so in sistemasOperativos" :key="so">
                  <td>{{ so }}</td>
                  <td class="text-right">
                    <button class="btn btn-outline-success btn-sm"
                      @click="configOvpn(so)">
                      <i class="mdi mdi-download"></i>
                      Descargar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
import API from '@/API.js'
export default {
  name: 'MdlDescargaConfiguracion',
  data() {
    return {
      idCredencial: null,
      identidad: '',
      sistemasOperativos: ['Windows', 'MacOS', 'Linux', 'Android', 'iOS', 'Mikrotik']
    }
  },
  methods: {
    configOvpn(so) {
      let tk = localStorage.getItem('argusblack.token')
      so = so.toLowerCase()

      if (so != 'mikrotik')
        window.open(`${API}/vpn/credenciales/${this.idCredencial}/config.ovpn.zip?so=${so}&_tk=${tk}`, '_blank')
      else
        window.open(`${API}/vpn/credenciales/${this.idCredencial}/config.ovpn.rsc.zip?_tk=${tk}`, '_blank')
    },
    mostrar(idCredencial, identidad) {
      this.idCredencial = idCredencial
      this.identidad = identidad

      var modal = new Modal(this.$refs.MdlDescargaConfiguracion)
      modal.show()
    }
  }
}
</script>

<style scoped>

</style>